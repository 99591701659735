import * as React from "react"
import Layout from "../components/layout";
import {Helmet} from "react-helmet";
import Header from "../components/header";

const ErrorPage = () => {
    return (
        <Layout>
            <Helmet>
                <title>404 - Zempléni Hiúzok</title>
            </Helmet>
            <Header/>

        </Layout>
    )
}

export default ErrorPage
